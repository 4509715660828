import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

import type { Patient } from "api/models/Patient";
import { ArrowRightIcon, DropdownIcon, PhoneCallIcon, StarYellow } from "assets";
import { VideoIcon } from "assets/videocall";

const isPatientDischarged = (patient: Patient) => {
  return (
    (patient?.state === "in_selfcare" && !patient?.ready_for_discharge) ||
    patient?.state === "discharged" ||
    patient?.state === "treatment_ended"
  );
};

const isPatientVisible = (patient: Patient) => {
  return (
    (!patient?.deleted_at && !(isPatientDischarged(patient) && !patient?.pt_on_demand_enabled)) ||
    patient.premium_type === "guided_care"
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getElementPath = (el: HTMLElement) => {
  const path: (HTMLElement | Document | (Window & typeof globalThis))[] = [];
  let currentElem: HTMLElement | null = el;
  while (currentElem) {
    path.push(currentElem);
    currentElem = currentElem.parentElement;
  }
  if (path.indexOf(window) === -1 && path.indexOf(document) === -1) path.push(document);
  if (path.indexOf(window) === -1) path.push(window);
  return path;
};

const Priority = styled(StarYellow)<{ $hidden: boolean }>`
  position: absolute;
  visibility: ${props => (props.$hidden ? "hidden" : "visible")};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.greys.silver};
  padding: 4px;
  border-radius: 50%;
  bottom: 0;
  right: -8px;
  ${props => props.theme.belowBreakpoint} {
    transform: scale(0.7);
    bottom: -4px;
    right: -16px;
  }
`;
const AfterNameIcon = styled.div`
  margin: 4px auto auto ${props => props.theme.spacing.S_5};
`;
const BackButton = styled(NavLink)`
  color: ${props => props.theme.colors.primary.base};
  text-decoration: none;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`;

const GoBackText = styled.span`
  margin-left: ${props => props.theme.spacing.S_10};
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeight.medium};
`;

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.greys.silver};
  border-radius: ${props => props.theme.borderRadius.basic};
  margin-top: 16px;
`;

const Info = styled.div`
  display: flex;
`;

const AvatarContainer = styled.div`
  display: flex;
  position: relative;
`;

const Name = styled.div`
  color: ${props => props.theme.colors.primary.base};
  font-size: 18px;
  font-weight: 600;
`;

const ProgressBar = styled.div`
  width: 100px;
  height: 4px;
  border-radius: 2px;
  background: ${props => props.theme.colors.greys.light2};
  margin-right: ${props => props.theme.spacing.S_10};
`;

const Progress = styled.div<{ $value: number }>`
  width: ${props => props.$value}px;
  height: 4px;
  border-radius: 2px;
  background: ${props => (props.$value >= 60 ? props.theme.colors.green : props.theme.colors.orange)};
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 10px;
  height: fit-content;
  flex: 1;
  margin-top: 12px;
  color: ${props => props.theme.colors.redesign.db80};
  font-size: 14px;
  line-height: 24px;
  ${props => props.theme.belowBreakpoint} {
    margin-top: 0;
  }
`;

const TreatmentInfo = styled.dl`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 20px;
  margin: 0;
`;

const ListContainer = styled.div`
  width: 50%;
  display: flex;
  flex-flow: row nowrap;
  ${props => props.theme.belowBreakpoint} {
    width: 100%;
  }
`;

const List = styled.dl`
  flex: 1;
  margin: 0;
`;

const Label = styled.dt`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.redesign.db60};
`;

const DataWrapper = styled.div<{ $wrap?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: ${props => props.$wrap && "wrap"};
`;

const Spacer = styled.div<{ $left?: number; $right?: number }>`
  width: fit-content;
  height: fit-content;
  margin-left: ${props => props.$left || 0}px;
  margin-right: ${props => props.$right || 0}px;
`;

const Data = styled.dd`
  color: ${props => props.theme.colors.redesign.db80};
  font-size: 14px;
  margin: 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
  &:empty {
    display: none;
  }
`;

const Button = styled.button<{ $justifyContent?: string; disabled?: boolean; main?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${props => props.$justifyContent || "center"};
  align-items: center;
  outline: none;
  flex: 1;
  width: 100%;
  padding: ${props => props.theme.spacing.S_15};
  background: transparent;
  border: none;
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
  font-size: 18px;
  color: ${props => props.theme.colors.primary.base};
  stroke: none;
  user-select: none;
  ${props =>
    props.disabled &&
    ` color: ${props.theme.colors.greys.pinkish};
      stroke: ${props.theme.colors.greys.pinkish};
  `}
  ${props =>
    props.main
      ? `:first-child {
            border-radius: 0 0 0 ${props.theme.borderRadius.basic};
        }
        :last-child {
          border-radius: 0 0 ${props.theme.borderRadius.basic} 0;
        }
        `
      : ""}
  :last-of-type {
    border-right: none;
  }
  :hover {
    cursor: pointer;
    background: ${props =>
      props.disabled ? props.theme.colors.redesign.disabled_grey : props.theme.colors.redesign.b30};
  }
  > svg {
    margin-right: ${props => props.theme.spacing.S_10};
  }
  li & {
    justify-content: start;
    padding: 0;
  }
`;

const DropdownButton = styled.button<{ $justifyContent?: string }>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${props => props.$justifyContent || "center"};
  align-items: center;
  outline: none;
  flex: 1;
  width: 100%;
  padding: 0px;
  background: transparent;
  border: none;
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
  font-size: 18px;
  color: ${props => props.theme.colors.primary.base};
  user-select: none;
  :last-of-type {
    border-right: none;
  }
  > svg {
    margin-right: ${props => props.theme.spacing.S_10};
  }
  li & {
    justify-content: start;
    padding: 0;
  }
`;

const LinkButton = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${props => props.theme.spacing.S_15};
  background: transparent;
  border-right: 1px solid ${props => props.theme.colors.greys.silver};
  font-size: 18px;
  color: ${props => props.theme.colors.primary.base};
  text-decoration: none;
  user-select: none;
  :hover {
    cursor: pointer;
    background: ${props => props.theme.colors.redesign.b30};
  }
  > svg {
    margin-right: ${props => props.theme.spacing.S_10};
  }
  li & {
    justify-content: start;
    padding: 0;
  }
`;

const DropdownItem = styled.li<{ disabled?: boolean }>`
  box-sizing: border-box;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid ${props => props.theme.colors.greys.silver};
  padding-left: ${props => props.theme.spacing.S_20};
  padding-right: ${props => props.theme.spacing.S_20};
  &:first-child {
    border-top: none;
  }
  & a :nth-child(n + 2) {
    margin-left: ${props => props.theme.spacing.S_20};
  }
  :hover {
    cursor: pointer;
    background: ${props =>
      props.disabled ? props.theme.colors.redesign.disabled_grey : props.theme.colors.redesign.b30};
  }
`;

const MobileContainer = styled.div<{ $offsetTop: number }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  max-height: 70px;
  padding: 0 ${props => props.theme.spacing.S_15};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  padding-top: ${props => props.$offsetTop}px;
`;

const MobileContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  > * {
    margin-right: ${props => props.theme.spacing.S_10};
  }
`;

const MobileName = styled.div`
  color: ${props => props.theme.colors.greys.slate};
  ${props => props.theme.font.header4}
  margin-left: 8px;
`;

const MobileListItem = styled.div`
  margin-bottom: 10px;
`;

const ErrorWrapper = styled.div`
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// TODO: Change to $open
const AnimatedDropdownIcon = styled(DropdownIcon)<{ open: boolean }>`
  transition: transform 300ms;
  transform: ${props => (props?.open ? "rotate(180deg)" : "rotate(0deg)")};
  margin-bottom: ${props => props.theme.spacing.S_5};
`;

const CallOngoingContainer = styled.div`
  ${props => props.theme.font.body1}
  align-items: center;
  background-color: ${props => props.theme.colors.redesign.b10};
  border-bottom: ${props => `1px solid ${props.theme.colors.greys.silver}`};
  border-radius: ${props => `${props.theme.borderRadius.basic} ${props.theme.borderRadius.basic} 0 0`};
  display: flex;
  flex: 1;
  height: 55px;
  justify-content: center;
`;

const InformationContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.redesign.b10};
  border: 1px solid ${({ theme }) => theme.colors.redesign.b50};
  border-radius: ${({ theme }) => theme.borderRadius.basic};
  margin-top: 16px;
  ${props => props.theme.belowBreakpoint} {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
`;

const InformationContainerHeader = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.primary.base};
  border-style: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 400;
  justify-content: space-between;
  line-height: 1.5em;
  padding: 16px 24px;
  width: 100%;
`;

const TreatmentPaneWrapper = styled.div<{ $isCurrentTreatment: boolean }>`
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ $isCurrentTreatment, theme: { colors } }) =>
    $isCurrentTreatment ? colors.treatmentSwitching.current_bg : colors.treatmentSwitching.previous_bg};
  padding: 16px 20px;
  min-width: 300px;
  border-radius: 8px;
  height: fit-content;
  ${props => props.theme.belowBreakpoint} {
    margin-top: 12px;
  }
`;

const PatientDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  ${props => props.theme.belowBreakpoint} {
    flex-direction: column;
    padding: 12px;
  }
`;

const VideoCallIconSmall = styled(VideoIcon)`
  height: 26px;
  circle {
    display: none;
  }
`;

const DownLoadIcon = styled(ArrowRightIcon)`
  margin-left: 4px;
  width: 22px;
  background-color: ${props => props.theme.colors.redesign.b100};
  padding: 2px 0;
  transform: rotate(90deg);
`;

const VideoReq = styled.div`
  padding-right: 4px;
  display: flex;
  align-items: center;
  height: 16px;
  left: 152px;
  top: 4px;
  background-color: #ff5959;
  border-radius: 10px;
  font-size: 11px;
  line-height: 14px;
  color: white;
  width: fit-content;
`;

const CareEventsCounterWrapper = styled.div`
  display: flex;
`;

const DotsWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: 8px;
`;

const Dot = styled.div`
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin-right: 4px;
`;

const FullDot = styled(Dot)`
  background-color: ${props => props.theme.colors.redesign.b80};
`;

const EmptyDot = styled(Dot)`
  background-color: ${props => props.theme.colors.redesign.db30};
`;

const DotSeparator = styled(Dot)`
  margin: 0 8px;
  background-color: ${props => props.theme.colors.redesign.db60};
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  margin-left: 20px;
`;

const PersonalDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.redesign.db60};
  margin-top: 8px;
  font-size: 14px;
  ${props => props.theme.belowBreakpoint} {
    border-bottom: 1px solid ${props => props.theme.colors.redesign.db20};
    padding: 4px 0 12px 12px;
  }
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: ${props => props.theme.colors.redesign.b100};
  font-weight: 600;
`;

const StyledPhoneCallIcon = styled(PhoneCallIcon)`
  height: 18px;
  margin-right: 4px;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
`;

const RegionData = styled.div`
  display: flex;

  ${({ theme }) => theme.belowBreakpoint} {
    display: block;
  }
`;

export {
  isPatientDischarged,
  isPatientVisible,
  getElementPath,
  TreatmentPaneWrapper,
  Priority,
  AfterNameIcon,
  BackButton,
  GoBackText,
  Container,
  Info,
  AvatarContainer,
  Name,
  ProgressBar,
  Progress,
  TreatmentInfo,
  ListContainer,
  List,
  Label,
  Data,
  Buttons,
  Button,
  LinkButton,
  DropdownItem,
  MobileContainer,
  MobileContent,
  MobileName,
  MobileListItem,
  ErrorWrapper,
  AnimatedDropdownIcon,
  InfoGrid,
  DataWrapper,
  Spacer,
  CallOngoingContainer,
  DropdownButton,
  InformationContainer,
  InformationContainerHeader,
  VideoCallIconSmall,
  StyledPhoneCallIcon,
  PhoneNumberWrapper,
  PersonalDetailsContainer,
  NameContainer,
  FullDot,
  EmptyDot,
  DotSeparator,
  DotsWrapper,
  CareEventsCounterWrapper,
  VideoReq,
  DownLoadIcon,
  PatientDetailsContainer,
  TopSection,
  RegionData,
};
