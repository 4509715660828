import type { PatientProfile } from "./PatientProfile";
import type { User } from "./User";

export const AppointmentToSchedule = {
  FACE_TO_FACE: "face_to_face",
  FOLLOW_UP: "follow_up",
  FOLLOW_UP_VIDEO_CALL: "follow_up_video_call",
  FOLLOW_UP_GUIDED_CARE: "follow_up_guided_care",
  FOLLOW_UP_CAP_RESET: "follow_up_cap_reset",
  FOLLOW_UP_RESUME_TREATMENT: "follow_up_resume_treatment",
  DISCHARGE_CALL: "discharge_call",
  KICK_OFF: "kick_off",
  GENERAL: "general",
  PHONE: "phone",
  VIDEO: "video",
  ADHERENCE: "adherence",
  TREATMENT_SWITCHED: "treatment_switched",
} as const;
export type AppointmentToSchedule = typeof AppointmentToSchedule[keyof typeof AppointmentToSchedule];

/** @see https://github.com/Jojnts/jojnts-service/blob/develop/spec/swagger_helper.rb#L685 */
export type Patient = User &
  PatientProfile & {
    account_alerts: string[];
    appointment_to_schedule: AppointmentToSchedule | null;
    covered?: boolean;
    exempted_from_patient_fee: boolean;
    free_trial?: boolean;
    group_eligibility?: GroupEligibility;
    group_membership_id?: number;
    orthopedist?: string;
    payer?: Payer | null;
    prio?: boolean; // undocumented
    therapist?: string;
    treatment_pause: TreatmentPause | null;
    referral_process_status: string | null;
    review: boolean;
    deleted_at: string | null;
    ready_for_discharge?: boolean;
  };

type GroupEligibility = {
  eligible: boolean;
  reason?: GroupEligibilityReason;
};

type GroupEligibilityReason =
  | "in_unavailable_countr"
  | "member_of_a_group"
  | "unfinished_activities_in_introduction"
  | "unfinished_required_activities";

type Payer = {
  category: string;
  co_payer: boolean | null;
  fee_level: string | null;
  id: number;
  name: string | null;
};

type TreatmentPause = {
  starts_at: string;
  ends_at: string;
  currently_active: boolean;
};
