import type React from "react";
import { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { CountryData } from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import invariant from "ts-invariant";
import { z } from "zod";

import { usePutPatientOnboarding } from "api/hooks/useManagerBooking";
import type { PatientOnboardingPayload } from "api/schemas/ManagerBooking";
import { useProfileContext } from "contexts/ProfileContext";
import { emailSchema, nonemptyStringSchema } from "routes/login/utils/validation/emailPasswordSchemas";
import { StyledPhoneInput } from "routes/patients/PatientsList/InvitePatient/InvitePatientForm/InvitePatientForm";
import { PrimaryButton } from "shared/atoms/Button";
import { MUITextInput } from "shared/atoms/inputs";
import { Notification } from "shared/atoms/Notification";

import { BOOKING_SCREENS } from "../AdminBookingForm";
import { Container } from "../misc";

import { Form } from "./CreatePatient";

const contactDetailsSchema = emailSchema.merge(z.object({ phone_number: nonemptyStringSchema }));

interface Props {
  patientId: number | undefined;
  email: string;
  phone: string;
  setEmail: (email: string) => void;
  setPhone: (phone: string) => void;
}

export const SelectContactDetails: React.VFC<Props> = ({ patientId, email, phone, setEmail, setPhone }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profile } = useProfileContext();
  const [error, setError] = useState<string | null>(null);

  const putPatientOnboarding = usePutPatientOnboarding();

  invariant(profile);

  const form = useForm<PatientOnboardingPayload>({
    defaultValues: {
      email,
      phone_number: phone,
    },
    resolver: zodResolver(contactDetailsSchema),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const defaultCountryCode = profile.market?.toLocaleLowerCase();

  const onSubmit = handleSubmit(formData => {
    setEmail(formData.email);
    setPhone(formData.phone_number);

    if (patientId) {
      putPatientOnboarding.mutateAsync(
        {
          data: {
            email: formData.email,
            phone_number: formData.phone_number,
          },
          managerId: profile.id,
          patientId,
        },
        {
          onSuccess: () => {
            navigate(`../${BOOKING_SCREENS.SUMMARY}`);
          },
          onError: e => {
            if (axios.isAxiosError(e)) {
              const alreadyOnboardedError = e.response?.data?.errors?.some(
                (err: string) => err === "onboarding already completed"
              );
              if (alreadyOnboardedError) {
                setError(t("errors.onboarding_already_completed"));
              } else if (e.response?.status === 422) {
                setError(t("errors.email_or_phone_number_already_in_use"));
              } else {
                setError(t("errors.generic"));
              }
            }
          },
        }
      );
    }
  });

  return (
    <Container>
      <FormProvider {...form}>
        <Form onSubmit={onSubmit} noValidate>
          <MUITextInput
            label={t("form.email")}
            name="email"
            error={errors?.email && t("errors.string.empty")}
            type="email"
            required
          />
          <Controller
            name="phone_number"
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledPhoneInput
                disableCountryGuess
                disableDropdown
                countryCodeEditable={false}
                country={defaultCountryCode}
                onlyCountries={["se"]}
                inputProps={{
                  name: "phone_number",
                  required: true,
                }}
                onChange={(inputValue, data: CountryData, event) => {
                  event.stopPropagation();
                  onChange(inputValue);
                }}
                value={value}
                placeholder={t("form.phone_number")}
              />
            )}
          />
          <PrimaryButton style={{ alignSelf: "center" }} disabled={putPatientOnboarding.isLoading}>
            {t("booking.buttons.continue")}
          </PrimaryButton>
        </Form>

        {error && (
          <Notification variant="danger" style={{ marginTop: "20px" }}>
            {error}
          </Notification>
        )}
      </FormProvider>
    </Container>
  );
};
